<template>
  <section id="dashboard" />
</template>

<script>

export default {
  components: {
  },
  data() {
    return {
      data: {},
    }
  },
  created() {

  },
}
</script>

<style lang="scss">
</style>
